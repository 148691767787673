/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useEffect, useState } from "react";
import FullPageLoader from "../containers/full-page-loader";
import SubscriptionHelper from "../helper-methods/subscription-helper";
import { showLoader } from "../redux/actions/loader-data";
import { store } from "../redux/store";
import TermPopup from "./terms-popup/terms-popup";

const CheckoutForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTermsVisible, setIsTermsVisible] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isOptInMessagesAccepted, setIsOptInMessagesAccepted] = useState(false);
  const _onAccept = () => {
    setIsTermsVisible(false);
    setIsTermsAccepted(true);
    setIsOptInMessagesAccepted(true);
  };
  const _submit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (
      firstName?.length &&
      lastName?.length &&
      phone?.length >= 10 &&
      isTermsAccepted
    ) {
      store.dispatch(showLoader());
      const formattedPhone = phone.replace(/-/g, "");
      SubscriptionHelper.storeUserData({
        firstName,
        lastName,
        phone: `+1${formattedPhone}`,
      });
      const { data } = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/stripe`,
        {
          appBaseUrl: window.location.origin,
        }
      );
      if (data?.url) {
        window.location.href = data.url;
      }
      store.dispatch(showLoader());
    }
  };
  return (
    <>
      <TermPopup
        isVisible={isTermsVisible}
        onAccept={_onAccept}
        onDismiss={(e) => setIsTermsVisible(false)}
      />
      <form id="stripeForm" onSubmit={_submit}>
        <div className="additionalFormData container-fluid">
          <h3>Please provide the details below</h3>
          <div className="row">
            <div className="col-lg-6">
              <div className="inputWrapper">
                <div className="label">First Name</div>
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <p className="errorText">
                  {isSubmitting && !firstName?.length ? "Cannot be blank" : ""}
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="inputWrapper">
                <div className="label">Last Name</div>
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <p className="errorText">
                  {isSubmitting && !lastName?.length ? "Cannot be blank" : ""}
                </p>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="inputWrapper">
                <div className="label">Mobile Number</div>
                <input
                  type="text"
                  value={`+1 ${phone}`}
                  onChange={(e) =>
                    setPhone(e.target.value.substring(3).replace(/[^\d-]/g, ""))
                  }
                />
                <p className="errorText">
                  {isSubmitting && phone?.length < 10
                    ? "Provide 10 digit number"
                    : ""}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="submissionWrapper">
                <div className="checkboxWrapper">
                  <input
                    type="checkbox"
                    defaultChecked={isTermsAccepted}
                    checked={isTermsAccepted}
                    onChange={() => setIsTermsAccepted(!isTermsAccepted)}
                  />{" "}
                  <span>
                    I agree with the{" "}
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        setIsTermsVisible(true);
                      }}
                    >
                      Terms and conditions
                    </a>
                  </span>
                </div>
                <div className="checkboxWrapper mt-0">
                  <input
                    type="checkbox"
                    defaultChecked={isOptInMessagesAccepted}
                    checked={isOptInMessagesAccepted}
                    onChange={() =>
                      setIsOptInMessagesAccepted(!isOptInMessagesAccepted)
                    }
                  />{" "}
                  <span>Opt-in to receive text messages</span>
                </div>
                <button
                  class="btn-get-started goButton"
                  disabled={!(isTermsAccepted && isOptInMessagesAccepted)}
                >
                  Continue
                </button>
                <div className="supportEmail">
                  <p>
                    CUSTOMER SUPPORT –{" "}
                    <a href="mailto:admin@mydailydose.info">
                      admin@mydailydose.info
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default CheckoutForm;
