

/*  Production Values  */

// export const BASE_URL = "https://raw.githubusercontent.com/debojyoti/Static-Data-Endpoints/master/";             //.. Production



/*  Dev values  */

export const BASE_URL = "http://www.mocky.io/v2/";          //.. Dev

