/* eslint-disable default-case */
import React, { Component, useState } from "react";
import { useEffect } from "react";
import CheckoutForm from "../checkout-form";
import { useLocation } from "react-router-dom";
import SubscriptionHelper from "../../helper-methods/subscription-helper";
import Swal from "sweetalert2";

const SubscriptionWizard = ({ gotoSampleMessages }) => {
  const [screenType, setScreenType] = useState("screen1");
  const { search } = useLocation();

  const _checkIfRedirected = async () => {
    const query = new URLSearchParams(search);
    const paramField = query.get("pt");

    if (paramField?.length) {
      if (paramField === "s") {
        // Success
        await SubscriptionHelper.addUserToGroup();
        Swal.fire("You have successfully subscribed", "", "success");
      } else {
        // Error
      }
    }
  };

  useEffect(() => {
    _checkIfRedirected();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [screenType]);

  const _renderStep = () => {
    switch (screenType) {
      case "screen1": {
        return (
          <>
            <h3>Let's get started!</h3>
            <br />
            <p className="fst-italic">
              Welcome to My Daily Dose, sobriety support right to your cell phone on autopilot.
             We text a motivational message to all of our subscribers each day.  With your 
             subscription, you’ll get an empowering daily support message sent right to your 
             phone.   No apps, websites, or email to login to.  You’ll be notified in your texting in 
             box automatically every time your Daily Dose arrives.
             </p>
             <p>Just open and take in the inspiration.</p>
             <p><h2>7-DAY Free Trial</h2></p>
            <p>Try before you subscribe.  You can cancel during the free trial period.
               And you can always cancel anytime by replying with the word STOP to any text.
            </p>
            <p>
              The introductory rate is only $4.95 per month. That’s about 16
              cents a day for fresh perspectives on critical recovery concepts,
              right at your fingertips. The rate you join at is permanently
              locked-in during your continuous subscription.
            </p>

            <p>
              Whether you are experienced in recovery or brand new to the
              community, a quality support system can enhance your continued
              success and growth. My Daily Dose text messages may be the most
              efficient and effective way to focus on your “sobriety mindset”
              every day. We hope to see you in the community.
            </p>
            <p>
              <span className="sampleMessageLink" onClick={gotoSampleMessages}>
                Preview actual Daily Dose texts{" "}
              </span>{" "}
              below then click on 7-Day free trial link.
            </p>
            <p><a href="mailto:admin@mydailydose.info">Admin@MyDailyDose.info</a></p>

            <button
              class="btn-get-started goButton"
              onClick={(e) => setScreenType("screen2")}
            >
              Trial Subscription &nbsp;{" "}
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </>
        );
      }
      case "screen2": {
        return (
          <>
            <CheckoutForm />
          </>
        );
      }
    }
  };

  return (
    <>
      <div className="content pt-4 pt-lg-0">{_renderStep()}</div>
    </>
  );
};

export default SubscriptionWizard;
