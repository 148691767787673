const InitialScript = () => {
  setTimeout(() => {
    var tag = document.createElement("script");
    tag.async = false;
    tag.src =
      "https://cdnjs.cloudflare.com/ajax/libs/Swiper/8.4.4/swiper-bundle.js";
    var body = document.getElementsByTagName("body")[0];
    body.appendChild(tag);
    // body.removeChild();
    // alert('loaded')
    new window.Swiper(".testimonials-slider", {
      speed: 600,
      loop: true,
      simulateTouch:false,
      autoplay: false,
      slidesPerView: "auto",
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },

        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      },
    });
    let interRef = setInterval(() => { 
      var frames = document.getElementsByTagName("iframe");
      console.log('frames', frames)
      if (frames?.length) {
        body.removeChild(frames[0]);
      } else {
        clearInterval(interRef);
      }
     }, 2000)
  }, 100);
};

export default InitialScript;
