import React, { useEffect, useRef } from "react";
import Image from "../../components/image";
import SubscriptionWizard from "../../components/subscription-wizard/subscription-wizard";
import FullPageLoader from "../../containers/full-page-loader";
import Constants from "./constants";
import "./home-page.scss";
import InitialScript from "./initial-script";

const HomePage = () => {
  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView();

  useEffect(() => {
    InitialScript();
  }, []);


  return (
    <>
      <div>
        {/* ======= Header ======= */}
        <header id="header" className="fixed-top d-flex align-items-center">
        <FullPageLoader></FullPageLoader>
          <div className="container d-flex align-items-center">
            <div className="logo me-auto">
              {/* <Image
                  // src="assets/img/hero-img.png"
                  src="assets/img/Brain Gears.jpg"
                  className="img-fluid"
                  alt
                />
              <h1>
                <a href="index.html">My Daily Dose</a>
              </h1> */}
              {/* Uncomment below if you prefer to use an image logo */}
              <a href="index.html">
               
                My Daily Dose
              </a>
            </div>
            <nav id="navbar" className="navbar order-last order-lg-0">
              {/* <ul>
                  <li>
                    <a className="nav-link scrollto active" href="#hero">
                      Home
                    </a>
                  </li>
                  <li className="dropdown">
                    <a href="#">
                      <span>About</span> <i className="bi bi-chevron-down" />
                    </a>
                    <ul>
                      <li>
                        <a className="nav-link scrollto" href="#about">
                          About Us
                        </a>
                      </li>
                      <li>
                        <a className="nav-link scrollto" href="#team">
                          Team
                        </a>
                      </li>
                      <li>
                        <a className="nav-link scrollto" href="#testimonials">
                          Testimonials
                        </a>
                      </li>
                      <li className="dropdown">
                        <a href="#">
                          <span>Deep Drop Down</span>{" "}
                          <i className="bi bi-chevron-right" />
                        </a>
                        <ul>
                          <li>
                            <a href="#">Deep Drop Down 1</a>
                          </li>
                          <li>
                            <a href="#">Deep Drop Down 2</a>
                          </li>
                          <li>
                            <a href="#">Deep Drop Down 3</a>
                          </li>
                          <li>
                            <a href="#">Deep Drop Down 4</a>
                          </li>
                          <li>
                            <a href="#">Deep Drop Down 5</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="nav-link scrollto" href="#services">
                      Services
                    </a>
                  </li>
                  <li>
                    <a className="nav-link scrollto " href="#portfolio">
                      Portfolio
                    </a>
                  </li>
                  <li>
                    <a className="nav-link scrollto" href="#pricing">
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a className="nav-link scrollto" href="#contact">
                      Contact
                    </a>
                  </li>
                </ul> */}
              <i className="bi bi-list mobile-nav-toggle" />
            </nav>
            {/* .navbar */}
           
          </div>
        </header>
        {/* End Header */}
        {/* ======= Hero Section ======= */}
        {/* End Hero */}
        <main id="main">
          {/* ======= About Section ======= */}
          <section id="about" className="about">
            <div className="container">
              <div
                className="row align-items-center"
                style={{ minHeight: "500px" }}
              >
                <div className="col-lg-6" data-aos="zoom-in">
                  <img
                    src={require("../../assets/img/Mind.jpg")}
                    // src="https://im4.ezgif.com/tmp/ezgif-4-89922e04e5.png"
                    className="img-fluid"
                    alt
                  />
                </div>
                <div
                  className="col-lg-6 d-flex flex-column justify-contents-center"
                  data-aos="fade-left"
                >
                  <SubscriptionWizard gotoSampleMessages={executeScroll} />
                </div>
              </div>
            </div>
          </section>
          <section id="testimonials" className="testimonials" ref={myRef}>
            <div className="container">
              <div className="section-title" data-aos="fade-up">
                <h2>Preview of Actual Texts</h2>
                <p>
                  {/* Magnam dolores commodi suscipit. Necessitatibus eius
                  consequatur ex aliquid fuga eum quidem. Sit sint consectetur
                  velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit
                  suscipit alias ea. Quia fugiat sit in iste officiis commodi
                  quidem hic quas. */}
                </p>
              </div>
              <div
                className="testimonials-slider swiper"
                data-aos="fade-up"
                data-aos-delay={100}
              >
                <div className="swiper-wrapper">
                  {Constants.map((message, messageIndex) => (
                    <div className="swiper-slide" key={messageIndex}>
                      <div className="testimonial-item">
                        <p>
                          <i className="bx bxs-quote-alt-left quote-icon-left" />
                          {message.text}
                          <i className="bx bxs-quote-alt-right quote-icon-right" />
                        </p>
                        {/* <Image src="assets/img/testimonials/testimonials-1.jpg" className="testimonial-img" alt /> */}
                        <h3>{message.title}</h3>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="swiper-pagination" />
              </div>
            </div>
            <div className="topButton" onClick={e => {
                window.scrollTo(0, 0)
            }}>
            <i className="fa-solid fa-arrow-up"></i>
            </div>
          </section>
          {/* End Testimonials Section */}
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}

        {/* End Footer */}
      </div>
    </>
  );
};

export default HomePage;
