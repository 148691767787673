const Constants = [
  {
    title: "#1 – FULLY SURRENDERED",
    text: "Most of us go years trying to “control and enjoy” our drug of choice.  All the time thinking that we aren’t really addicts. (Denial) Recognize the feeling?   One reason we resist SURRENDERING is because the word is usually associated with failure.    And nobody wants to be a failure.  But in Recovery, we form a new relationship to SURRENDERING.  It is called FREEDOM.   Here you alter your relationship to the word “surrender”.  This is an internal paradigm shift.   Resistance and fighting equals Slavery.  Surrender equals Freedom.   Without fully surrendering to the process, we are spinning our wheels and staying in suffering, enslaved to our old ways of thinking and our old habits.   Today consider if you are fully surrendered.   And again tomorrow and every day when you wake up.   Am I fully surrendered?   ",
  },
  {
    title: "#2 – FOR GOOD OR BAD",
    text: "Addicts tend to operate under a variety of false assumptions.  One of the most egregious is that they believe they are living in a vacuum, that their actions don’t affect others.    In reality, of course, the opposite is true.   “Stoned” behavior is like throwing a real stone into the serenity of a mirror-smooth lake.  The ripples disrupt the peace of everyone in the wake, full circle, 360 degrees.   Not one person lives in a vacuum.   Our lives, actions, and words, affect every person in our circle, for good or bad.  ",
  },
  {
    title: "#3 – UNDER THE GREAT ROOF OF THE SKY",
    text: "Get outdoors.  Feel the Sun warm your skin, the snowflake melting on your tongue, or just kick through a pile of Autumn leaves.   Escaping from the physical walls that surround us indoors releases our limiting thoughts by reconnecting with our primal nature outdoors.   Observe with gratitude everything that is available to witness in that moment. Being alive means your feet are still pointing toward the center of the Earth and your head is pointed upward to the Great Roof of The Sky on this tiny spec of sand in the Universe – That’s the Miracle!",
  },
  {
    title: "#4 – INTO ACTION",
    text: "Sobriety is a verb – an action word, not a state of being.  It means we are actively engaged in compassion, non-attached appreciation, generosity, kindness, honesty, integrity, and service.  These are our guiding principles.  They have nothing to do with a belief in a supreme being, rather a belief in Humanity.  Ponder these 7 guiding principles. ",
  },
  {
    title: "#5 - EVERYONE IS AN ADDICT ",
    text: "EVERYONE IS AN ADDICT – Sorry.  It’s just human nature.   The definition of addiction is consuming more of anything than can be tolerated and not being able to stop that behavior under current operating conditions.   People may think that because they aren’t in a rehab facility that they aren’t addicts.   Nonsense!   People are addicted to work, to sports, gambling, sex, television, food, sugar, fat, laziness, fame, recognition, etc.   Addicts must have their drug of choice.   The good news is, you are aware of your addictions, and you have a way to successfully manage them and be free from the pain and suffering of your addictions if you choose.   Most people aren’t even aware of their addictions.    ",
  },
  {
    title: "#6 - RIGOROUS HONESTY",
    text: "RIGOROUS HONESTY – The deep recess of our minds is where we conceal the facts we choose to ignore.   Avoidance provides a temporary shield from the painful truth.  But it’s like having a sliver under your skin.   The healing process can start after the irritant is removed.   The spiritual irritants are the things we have said and done that have caused pain and suffering.  Rigorous honesty to bring the irritants into the light underpins all of our recovery work.   This is the unadulterated searching and examining of what is, acknowledging what is and moving forward from that point.   Rigorous honesty is existential, as in pertaining to your very existence.   Rigorous honesty is your commitment to discovering and processing the truth, the whole truth and nothing but the truth, so help you God.",
  },
];

export default Constants;
