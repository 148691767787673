import axios from "axios";

const SubscriptionHelper = {
  storeUserData: (userData) => {
    localStorage.setItem("sud", btoa(JSON.stringify(userData)));
  },
  getUserData: () => {
    const userData = localStorage.getItem("sud");
    if (userData) {
      return JSON.parse(atob(userData));
    }
  },
  clearUserData: () => {
    localStorage.removeItem("sud");
  },
  addUserToGroup: async () => {
    const userData = SubscriptionHelper.getUserData();
    if (userData) {
      const payload = {
        cell: userData.phone,
        firstname: userData.firstName,
        lastname: userData.lastName,
      };
      console.log("payload", payload);
      SubscriptionHelper.clearUserData();
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/add`, payload);
    }
  },
};

export default SubscriptionHelper;
