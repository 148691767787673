/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Modal from "react-awesome-modal";

const TermPopup = ({ isVisible, onAccept, onDismiss }) => {
  return (
    <div className="modalWrapper">
      <Modal
        visible={isVisible}
        width="60%"
        height="400"
        effect="fadeInUp"
        onClickAway={onDismiss}
      >
        <div className="termsWrapper">
          <div className="termsTitle">
            <h3>MDD Terms & Conditions</h3>
          </div>
          <div className="termsContent">
            <p>
              By subscribing to MyDailyDose.info I agree to receive automated Transactional messages. I acknowledge 
              that the text messages are only the opinion of the author. MyDailyDose.info makes no claim that the 
              information provided is intended as treatment advise of any kind, nor is it intended to treat any 
              medical or psychological condition. I agree to hold MyDailyDose.info harmless of any liability whatsoever. 
              I agree to allow a recurring monthly payment of the current subscription amount. I understand that 
              the MyDailyDose.info texting service may be discontinued for non-payment. I understand I may cancel 
              my subscription at any time for any reason by replying to any text with the word STOP. If I choose 
              to cancel my subscription by replying to any text with the word STOP, the daily texts will stop 
              immediately. I understand that the credit card processor cannot issue partial refunds.   
            </p>
          </div>
          <div className="termsActions">
            <button className="agreeBtn" onClick={onAccept}>
              I agree
            </button>
            <p className="dismissLink">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  onDismiss();
                }}
              >
                Dismiss
              </a>
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TermPopup;
